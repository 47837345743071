import YouTube from '@/lib/youtube';
import { trackYouTube } from '@/lib/tracking';

const jwplayer = window.jwplayer;

let homeTwo;
let homeThree;

// jwplayer("jwplayer-home").setup({
//   file: "/assets/videos/home-video-1.mp4",
//   image: "/assets/img/investor-ask-questions-thumbnail.jpg",
//   width: "100%",
//   aspectratio: "16:9",
//   events: {
//     onPlay: function() {
//       homeTwo.pauseVideo();
//       homeThree.pauseVideo();
//     }
//   }
// });

YouTube.then(function (YT, createYouTubePlayer) {
  homeTwo = createYouTubePlayer({
    id: 'homeTwo',
    onPlay: function () {
      jwplayer('jwplayer-home').stop();
      homeThree.pauseVideo();
    },
    onStart: function () {
      trackYouTube(1097317, this);
    }
  });

  homeThree = createYouTubePlayer({
    id: 'homeThree',
    onPlay: function () {
      jwplayer('jwplayer-home').stop();
      homeTwo.pauseVideo();
    },
    onStart: function () {
      trackYouTube(1097318, this);
    }
  });
});
