function trackingClick() {
  if(typeof invChanOnClickTracking == 'function') { invChanOnClickTracking(); }
  if(typeof FbOnClickTracking == 'function') { FbOnClickTracking('purchase'); }
  if(typeof MqOnClickTracking == 'function') { MqOnClickTracking('siteactivity'); }
}

function trackYouTube(id, context) {
  mmConversionTag(id, context);
  yahooPixel("ria");
  trackingClick();

  window._qevents.push(
    {qacct:"p-vy-NVaqS_YS4b",labels:"_fp.event.RIASFY Site Activity", event:"refresh"}
  );
}

export {
  trackingClick,
  trackYouTube,
};
