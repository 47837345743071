import jQuery from 'jquery';

var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


function createYouTubePlayer(options) {
  var started = false;
  let YT = window.YT;
  
  return new YT.Player(options.id, {
    events: {
      'onStateChange': function (event) {
        if (event.data === YT.PlayerState.PLAYING) {
          if (typeof options.onPlay === 'function') {
            options.onPlay();
          }

          if (!started) {
            started = true;
            if (typeof options.onStart === 'function') {
              options.onStart();
            }
          }
        }
      }
    }
  });
}

export default jQuery.Deferred(dfd => {
  window.onYouTubeIframeAPIReady = function () {
    dfd.resolve(window.YT, createYouTubePlayer);
  };
});
